import React, { useEffect } from 'react';
import bgimg from '../images/homeimg.jpeg';
import HeroSection from '../components/home/HeroSection';
import FeaturesSection from '../components/home/FeaturesSection';
import SuccessThroughTheYears from '../components/home/SuccessThroughTheYears';
import ClientTestimonialsCarousel from '../components/services/ClientTestimonialsCarousel';
import OurServices from '../components/home/OurServices';
import ExplainServices from '../components/home/ExplainServices';
import MovingTools from '../components/movingtools/MovingTools';
import FounderWords from '../components/home/FounderWords';
import MovingClients from '../components/movingtools/MovingClients'


const HomePage = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
     <div className='bg-[#f0f4f8]'>
      <HeroSection/>
      <FounderWords/>
      <OurServices/>
      <SuccessThroughTheYears/>
      <MovingTools/>
      <ClientTestimonialsCarousel/>
      {/* <MovingClients/> */}
     </div>
    </>
  );
};

export default HomePage;
