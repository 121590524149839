import React, { useEffect } from 'react';

const PrivacyPolicy = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component is rendered
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-[#f0f4f8] py-12">
      <div className="container mx-auto px-4 pt-12">
        {/* Header Section */}
        <h2 className="text-4xl font-bold text-[#17315c] text-center mb-8">Privacy Policy</h2>
        <p className="text-center mb-4">Effective Date: October 1, 2024</p>
        
        {/* Policy Content */}
        <div className="bg-white shadow-lg rounded-lg p-8 mb-8">
          <h3 className="text-2xl font-semibold text-[#333] mb-4">Introduction</h3>
          <p className="text-[#333] mb-4">
            Leads4Contact ("we," "our," or "us") is committed to safeguarding your privacy. This Privacy Policy explains
            how we collect, use, disclose, and safeguard your information when you visit our website or use our services.
            By accessing or using our services, you acknowledge that you have read, understood, and agreed to the terms described herein.
          </p>
          <p className="text-[#333] mb-4">
            If you do not agree with our policies and practices, your choice is not to use our website or services.
            This policy may be updated from time to time, and we will notify you of any significant changes.
          </p>

          <h3 className="text-2xl font-semibold text-[#333] mb-4">Information We Collect</h3>
          <p className="text-[#333] mb-4">
            We collect various types of information to offer and enhance our services. The types of information we may
            collect include:
          </p>
          <ul className="list-disc list-inside mb-4 text-[#555]">
            <li><strong>Personal Information:</strong> This includes information that identifies you as an individual, such as your name, email address, phone number, postal address, job title, and company name.</li>
            <li><strong>Usage Data:</strong> Automatically collected data regarding your browsing activities, such as your IP address, browser type, device information, pages viewed, and time spent on each page.</li>
            <li><strong>Transaction Data:</strong> Information about transactions you engage in with us, including payment details and billing address.</li>
            <li><strong>Cookies and Tracking Technologies:</strong> Our website uses cookies, web beacons, and other tracking technologies to enhance your experience.</li>
          </ul>

          <h3 className="text-2xl font-semibold text-[#333] mb-4">How We Use Your Information</h3>
          <p className="text-[#333] mb-4">
            We use the information we collect for various purposes, including but not limited to:
          </p>
          <ul className="list-disc list-inside mb-4 text-[#555]">
            <li>Providing and maintaining our website and services.</li>
            <li>Processing your inquiries, requests, and orders.</li>
            <li>Communicating with you regarding our services and promotions.</li>
            <li>Personalizing and improving your experience on our website.</li>
            <li>Analyzing usage and traffic patterns to improve our services.</li>
            <li>Ensuring the security and integrity of our website.</li>
            <li>Complying with legal obligations and enforcing our agreements.</li>
          </ul>

          <h3 className="text-2xl font-semibold text-[#333] mb-4">How We Share Your Information</h3>
          <p className="text-[#333] mb-4">
            We do not sell, trade, or rent your personal information to third parties. However, we may share your information with:
          </p>
          <ul className="list-disc list-inside mb-4 text-[#555]">
            <li><strong>Service Providers:</strong> Third-party vendors who assist us in operating our website and providing services.</li>
            <li><strong>Business Transfers:</strong> Your information may be transferred in the event of a merger or acquisition.</li>
            <li><strong>Legal Requirements:</strong> We may disclose your information if required by law or to protect our rights.</li>
          </ul>

          <h3 className="text-2xl font-semibold text-[#333] mb-4">Data Security</h3>
          <p className="text-[#333] mb-4">
            We take reasonable steps to protect your data from unauthorized access or disclosure. Our security measures include encryption and secure access controls. However, no method of transmission over the internet is completely secure.
          </p>

          <h3 className="text-2xl font-semibold text-[#333] mb-4">Your Rights</h3>
          <p className="text-[#333] mb-4">
            You have the right to access, update, or delete your personal information at any time. You may also opt out of marketing communications.
          </p>
          <p className="text-[#333] mb-4">
            If you are in the EEA, you have additional rights under GDPR, including the right to data portability and the right to object to processing.
          </p>

          <h3 className="text-2xl font-semibold text-[#333] mb-4">International Data Transfers</h3>
          <p className="text-[#333] mb-4">
            Your information may be transferred to and processed in countries outside of your residence. We take appropriate measures to ensure your personal information is handled securely.
          </p>

          <h3 className="text-2xl font-semibold text-[#333] mb-4">Retention of Your Information</h3>
          <p className="text-[#333] mb-4">
            We retain your personal information only as long as necessary to fulfill the purposes for which it was collected.
          </p>

          <h3 className="text-2xl font-semibold text-[#333] mb-4">Third-Party Links and Services</h3>
          <p className="text-[#333] mb-4">
            Our website may contain links to third-party websites. We have no control over the content or privacy policies of those sites.
          </p>

          <h3 className="text-2xl font-semibold text-[#333] mb-4">Children's Privacy</h3>
          <p className="text-[#333] mb-4">
            Our services are not directed to individuals under 13, and we do not knowingly collect personal information from children.
          </p>

          <h3 className="text-2xl font-semibold text-[#333] mb-4">Changes to This Privacy Policy</h3>
          <p className="text-[#333] mb-4">
            We may update our Privacy Policy from time to time. We encourage you to review this policy periodically for any changes.
          </p>

          <h3 className="text-2xl font-semibold text-[#333] mb-4">Contact Us</h3>
          <p className="text-[#333] mb-4">
            If you have any questions or concerns about this Privacy Policy, please contact us at:
          </p>
          <p className="text-[#333] mb-4">
            Email: <a href="mailto:info@leads4contact.com" className="text-blue-500 underline">arehman@leads4contact.com</a>
          </p>
          <p className="text-[#333] mb-4">Phone: +92 3134250474</p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
